import { graphql } from 'gatsby'
import { Headline } from '@dfds-ui/react-components'

import { AspectImage } from '../Image'
import { FlexCard } from '../Card'
import { gridMap } from '../../utils'
import { EntryLink } from '../EntryLink'

const ContactBox = ({ blockWidth, contactContent, title, formsLink }) => (
  <FlexCard
    width={blockWidth ? gridMap[blockWidth] : gridMap['full-width']}
    media={
      contactContent.image && <AspectImage border {...contactContent.image} />
    }
  >
    <Headline as={'h3'}>{title}</Headline>
    <strong>{contactContent.name}</strong>
    <p>{contactContent.jobTitle}</p>
    <p>
      <a href={`tel:${contactContent.phoneNumber}`}>
        {contactContent.phoneNumber}
      </a>
    </p>
    <p>
      <a href={`mailto:${contactContent.email}`}>{contactContent.email}</a>
    </p>
    {formsLink && <EntryLink {...formsLink} />}
  </FlexCard>
)

export const contactFragment = graphql`
  fragment ContactBox on contentful_ContactBox {
    __typename
    sys {
      id
    }
    title
    blockWidth
    formsLink {
      __typename
      ...ItemLink
    }
    contactContent {
      name
      jobTitle
      phoneNumber
      email
      image {
        ...Asset
      }
    }
  }
`

export default ContactBox
